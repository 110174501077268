.cards {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.card {
    flex: 0 1 24%;
    width: 150px;
    border: #BD5332 1px solid;
    margin-bottom: 10px;
}

.card img {
    width: 150px;
}

.level-container {
    background-color: #BD5332;
    color: #fff;
}

.name-container {
    background-color: #BD5332;
    height: 45px;
    text-align: center;
    color: #fff;
}