html {
  height: 100%;
}

html, body, .App, #root, .main-content {
  min-height: 100vh;
}

.main-content {
  margin-left: 260px;
  margin-right: 260px;
  padding: 10px;
  background: #FF7043;
  border-left: 20px solid #BD5332;
  border-right: 20px solid #BD5332;
  min-width: 400px;
}

@media only screen and (max-width: 480px) {
  .main-content {
    margin-left: 0px;
    margin-right: 260px;
    padding: 10px;
    background: #FF7043;
    border-left: 20px solid #BD5332;
    border-right: 20px solid #BD5332;
    min-width: 100%;
  }
}

h1 {
  text-align: center;
  font-size: 72px;
}

h1 span {
  background: #333;
  color: #fff;
  padding: 5px;
  padding-bottom: 0px;
  border-radius: 5px;
}

.nes-container.with-title.is-centered > .title {
  background-color: #333;
  color: #fff;
}

.nes-container {
  border-color: #333 !important;
  margin-bottom: 20px;
}

.connect-wallet-container {
  text-align: center;
}

.sub-text {
  text-align: center;
}

.center {
  text-align: center;
}